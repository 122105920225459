<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
export default {
  page: {
    title: "Contrats",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
    Dataset,
    DatasetItem,
    DatasetPager,
    DatasetShow,
  },
  data() {
    return {
      title: "Accès",
      cols: [
        {
          name: "ID",
          field: "id",
          sort: "",
        },
        {
          name: "Nom complet",
          field: "full_name",
          sort: "",
        },
        {
          name: "@IP ",
          field: "ip",
          sort: "",
        },
        {
          name: "Navigateur",
          field: "browser",
          sort: "",
        },
        {
          name: "Date",
          field: "date",
          sort: "",
        },
        {
          name: "Action",
          field: "action",
          sort: "",
        },
      ],
      logs: [],
      items: [
        {
          text: "Paramètres",
          to: { name: "base.settings.index" },
        },
        {
          text: "Log D'accès",
          active: true,
        },
      ],
    };
  },

  watch: {},

  mounted() {
      this.fetchAccessLogList();
  },

  methods: {
    fetchAccessLogList() {
      var loader = this.$loading.show();
      this.$http
        .post("/auth/getAccessLog")
        .then((res) => {
          loader.hide();
          this.logs = res.data.original.logs;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <dataset v-slot="{ ds }" :ds-data="logs">
      <!-- <div class="row">
      <div class="col-md-4 mb-2 mb-md-0">
        <div class="form-group">
          <label for="formrow-inputCity">Type de Prestation *</label>
          <a href="javascrit:void(0)" @click="contractFilterForm.stdServices = []">Réinitialiser</a>
          <multiselect
                :searchable="true"
                :multiple="true"
                v-model="contractFilterForm.stdServices"
                class="here" 
                track-by="id"
                label="name"
                :options="stdServicesTypes"
                placeholder="Filtres"
                :select-label="''"
                :allow-empty="false"
              >
                <template slot="singleType" slot-scope="{ type }">{{
                  type.name
                }}</template>
              </multiselect>
              
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary mt-4" @click="filterContractsList">Filtrer</button>
      </div>
    </div> -->
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <table class="table table-hover d-md-table">
              <thead>
                <tr>
                  <th
                    v-for="th in cols"
                    :style="th.field == 'objet' ? 'width: 20%;' : ''"
                    :key="th.field"
                  >
                    {{ th.name }}
                  </th>
                </tr>
              </thead>
              <dataset-item tag="tbody">
                <template #default="{ row, rowIndex }">
                  <tr v-bind:key="rowIndex">
                    <td>{{ rowIndex + 1 }}</td>
                    <td>{{ row.full_name }}</td>
                    <td>
                      <a href="javascript:void()">{{ row.ip }}</a>
                    </td>
                    <td><a href="javascript:void()">{{ row.browser }}</a></td>
                    <td>{{ row.date }}</td>
                    <td><span v-html="row.action.html"></span></td>
                  </tr>
                </template>
              </dataset-item>
            </table>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-md-row flex-column justify-content-between align-items-center"
      >
        <!-- <dataset-info class="mb-2 mb-md-0" /> -->

        <dataset-show :ds-show-entries="5" />
        <dataset-pager />
      </div>
    </dataset>
  </Layout>
</template>
